<template>
  <router-view></router-view>
</template>
<style>
@import './assets/plugins/custom/datatables/datatables.bundle.css';
@import './assets/plugins/global/plugins.bundle.css';
@import './assets/css/style.bundle.css';

@import "vue-select/dist/vue-select.css";
</style>
